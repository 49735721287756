import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Article from '../components/articles/article'

const CaseTemplate = ({ data, location }) => {
  useEffect(() => {
    window.scroll(0, 0)
  }, [])

  return (
    <Layout template={'Case Template'} location={location}>
      <SEO
        title={data.wpCase.title}
        metaTitle={data.wpCase.seo.title}
        description={data.wpCase.seo.metaDesc}
      />
      <Article
        breadcrumbs={data.wpCase.seo.breadcrumbs}
        title={data.wpCase.title}
        excerpt={data.wpCase.excerpt}
        published={data.wpCase.date}
        modified={data.wpCase.modified}
        tags={data.wpCase.userGroups.nodes}
        featuredImage={data.wpCase.featuredImage.node}
        content={data.wpCase.templateCase}
        introduction={data.wpCase.templateCase.introductionText}
      />
    </Layout>
  )
}

export default CaseTemplate

export const query = graphql`
  query ($id: String) {
    wpCase(id: { eq: $id }) {
      title
      excerpt
      date(formatString: "DD.MM.YYYY")
      modified(formatString: "DD.MM.YYYY")
      featuredImage {
        node {
          publicUrl
          title
          altText
          caption
        }
      }
      seo {
        breadcrumbs {
          text
          url
        }
        title
        metaDesc
      }
      userGroups {
        nodes {
          taxonomyName
          name
          slug
        }
      }
      templateCase {
        introductionText
        summaryWidget {
          summaryContent
          summaryHeading
        }
        mainContent {
          ... on WpCase_Templatecase_MainContent_Wysiwyg {
            wysiwygContent
            fieldGroupName
          }
          ... on WpCase_Templatecase_MainContent_Media {
            fieldGroupName
            media {
              mediaSelect
              image {
                altText
                sourceUrl
                publicUrl
                caption
              }
              video {
                sourceUrl
                publicUrl
                title
                altText
                caption
              }
              file {
                caption
                localFile {
                  publicURL
                }
              }
            }
          }
          ... on WpCase_Templatecase_MainContent_Heading {
            fieldGroupName
            fontSize
            fontWeight
            headingTag
            headingText
          }
          ... on WpCase_Templatecase_MainContent_Button {
            fieldGroupName
            buttonList {
              buttonLink {
                target
                title
                url
              }
              buttonVariant
            }
          }
        }
        relatedCasesList {
          fieldGroupName
          case {
            ... on WpCase {
              id
              uri
              title
              featuredImage {
                node {
                  publicUrl
                  altText
                  caption
                }
              }
              templateCase {
                introductionText
                summaryWidget {
                  summaryContent
                  summaryHeading
                }
                mainContent {
                  ... on WpCase_Templatecase_MainContent_Wysiwyg {
                    wysiwygContent
                    fieldGroupName
                  }
                  ... on WpCase_Templatecase_MainContent_Media {
                    fieldGroupName
                    media {
                      mediaSelect
                      image {
                        altText
                        sourceUrl
                        publicUrl
                        caption
                      }
                      video {
                        sourceUrl
                        publicUrl
                        title
                        altText
                        caption
                      }
                    }
                  }
                  ... on WpCase_Templatecase_MainContent_Heading {
                    fieldGroupName
                    fontSize
                    fontWeight
                    headingTag
                    headingText
                  }
                }
              }
              excerpt
            }
          }
        }
      }
    }
  }
`
